import { type AnalyticsEventPayloads, stAnalytics } from "@repo/analytics";
import {
  type MessageInsertFileUpload,
  type ThreadMessage,
  ThreadMessageKinds,
  type curator,
  isMessageInsertFileUpload,
  isMessageInsertSignUpLimit,
  isMessageKnowledgeV1,
  isMessagePrompt,
  isMessageTextV1,
} from "@repo/client";
import { createElementSize } from "@solid-primitives/resize-observer";
import { useSearchParams } from "@solidjs/router";
import { TbBug, TbCheck, TbLoader2 } from "solid-icons/tb";
import {
  type Accessor,
  type Component,
  For,
  Match,
  Show,
  Switch,
  createEffect,
  createSignal,
  on,
  onCleanup,
  onMount,
} from "solid-js";
import { FloatingPromptBarContainer } from "~/components/_containers/FloatingPromptBarContainer";
import { StButton } from "~/components/buttons";
import { StIcon } from "~/components/icons";
import { PersistentPrompt } from "~/domains/chat/prompt/Prompt";
import { usePromptContext } from "~/domains/chat/prompt/PromptContext";
import { useIsIdentityConnecting } from "~/domains/identity/hooks";
import { isAuthenticatedIdentity } from "~/domains/identity/types";
import type { CampaingPageDataPrompt } from "~/domains/marketing/useUseCasesData";
import { TextPromptUnitV1 } from "~/domains/threads/components/units/TextPromptUnitV1";
import { TextUnitV1 } from "~/domains/threads/components/units/TextUnitV1";
import type { ThreadGroup, ThreadUnit } from "~/domains/threads/screens/ThreadScreen";
import { newClearErrors } from "~/domains/ws/machines";
import { NotFoundScreen } from "~/screens/NotFoundScreen";
import { useWire } from "~/wire";
import { ThreadTimelineUnit } from "../../ThreadTimelineUnit";
import { InsertFileUploadUnit } from "../../units/InsertFileUploadUnit";
import { InsertSignUpUnit } from "../../units/InsertSignUpUnit";
import { KnowledgeChangeUnitV1 } from "../../units/KnowledgeChangeUnitV1";
import { MarkdownRenderer } from "../../units/MarkdownRenderer";
import styles from "./ThreadPanel.module.css";

interface Props {
  prompt?: CampaingPageDataPrompt;
  messages: (ThreadGroup | ThreadUnit)[];
  isNewThreadId: boolean;
}

export const ThreadPanel = (props: Props) => {
  const wire = useWire();
  const [containerRef, setContainerRef] = createSignal<HTMLDivElement>();
  let threadEndRef!: HTMLDivElement;

  const thread = () =>
    wire.services.threads.snapshot.context.threadId
      ? wire.services.threads.getThread(wire.services.threads.snapshot.context.threadId)
      : undefined;

  /**
   * Sends data to analytics when an error is encountered.
   * @see {@link https://linear.app/storytell/issue/ENG-1444/tag-errors-in-posthog-so-we-can-track-them|ENG-1444}
   */
  const hasErrors = (): boolean => {
    const errors = wire.services.websocket.snapshot.context.errorMessages;
    if (wire.services.websocket.snapshot.context.errorMessages.length > 0) {
      errors.forEach((error) => {
        const data: AnalyticsEventPayloads["error_in_thread_view"] = {
          errorCode: error.code,
          errorMessage: error.message,
          email: null,
          userId: null,
        };
        if (
          wire.services.identity?.snapshot.context.identity &&
          isAuthenticatedIdentity(wire.services.identity.snapshot.context.identity)
        ) {
          data.email = wire.services.identity.snapshot.context.identity.email || null;
          data.userId = wire.services.identity.snapshot.context.identity.userId || null;
        }
        if (data.errorCode !== "not_found") {
          stAnalytics.track("error_in_thread_view", data);
        } else {
          stAnalytics.track("thread_not_found", data);
        }
      });
      return true;
    }
    return false;
  };

  const threadTitle = () => thread()?.label || "New";

  const isConnecting = useIsIdentityConnecting();

  const is404 = () => !isConnecting() && thread() === undefined && !props.prompt;

  const handleClearErrors = () => {
    wire.services.websocket.send(newClearErrors());
  };

  const [threadLoaded, setThreadLoaded] = createSignal(false);

  const loadUnload = (load: boolean) => () => {
    setThreadLoaded(load);
    return undefined;
  };

  onCleanup(() => {
    handleClearErrors();
  });

  useThreadAutoScroll(
    containerRef,
    () => threadEndRef,
    () => props.messages,
    threadLoaded,
  );

  const isCampaignScreen = () =>
    props.prompt && props.messages.filter((m) => m.type !== "unit" || m.content.kind !== "thread-insert").length === 0;

  return (
    <Switch>
      <Match when={is404()}>
        <NotFoundScreen />
      </Match>
      <Match when={isCampaignScreen() && wire.services.limiting.guest.lastInteraction()?.type !== "prompt"}>
        {loadUnload(false)()}
        <PredefinedPromptDetails prompt={props.prompt} />

        <FloatingPromptBarContainer>
          <PersistentPrompt />
        </FloatingPromptBarContainer>
      </Match>
      <Match
        when={
          isConnecting() ||
          (["loading", "idle"].includes(wire.services.threads.snapshot.value) &&
            !props.isNewThreadId &&
            !isCampaignScreen())
        }
      >
        {loadUnload(false)()}
        <div class="grid place-content-center h-96 text-slate-900 dark:text-slate-200">
          <StIcon icon={TbLoader2} class="animate-spin size-10" />
        </div>
      </Match>

      <Match when={(props.messages?.length || 0) === 0}>
        {loadUnload(false)()}
        <EmptyThreadMessage />

        <FloatingPromptBarContainer>
          <PersistentPrompt />
        </FloatingPromptBarContainer>
      </Match>
      <Match when={true}>
        {loadUnload(true)()}
        <>
          <div ref={setContainerRef} class={styles["thread-panel"]}>
            <Show when={threadTitle()}>
              <h2 class={styles["thread-panel__title"]}>{threadTitle()}</h2>
            </Show>
            <For each={props.messages}>
              {(group) => (
                <Show
                  when={group.type === "group"}
                  fallback={
                    <Switch>
                      <Match when={isMessageKnowledgeV1(group.content)}>
                        <KnowledgeChangeUnitV1 message={group.content as curator.MessageKnowledgeV1} />
                      </Match>
                      <Match when={isMessageInsertFileUpload(group.content)}>
                        <InsertFileUploadUnit message={group.content as MessageInsertFileUpload} />
                      </Match>
                      <Match when={isMessageInsertSignUpLimit(group.content)}>
                        <InsertSignUpUnit />

                        <Show when={wire.services.limiting.guest.lastInteraction()}>
                          <div class="relative pl-6 md:pl-12 mb-16 pt-10 w-[96%] mx-auto">
                            <div
                              aria-hidden
                              class="absolute left-0 top-14 rounded-full bottom-0 pointer-events-none w-[2px] bg-violet-700/40 dark:bg-violet-700"
                            />
                            <Show when={wire.services.limiting.guest.lastInteraction()?.type === "prompt"}>
                              <TextPromptUnitV1
                                message={{
                                  kind: ThreadMessageKinds.MessageKindPromptV2,
                                  messageId: "",
                                  prompt: (
                                    wire.services.limiting.guest.lastInteraction() as {
                                      prompt: string;
                                    }
                                  ).prompt,
                                  scope: {
                                    worldKnowledge: false,
                                    collectionIDs: [],
                                    assetIDs: [],
                                  },
                                  model: "",
                                  transformationId: "",
                                  temperature: 0,
                                  max_tokens: 0,
                                  top_p: 0,
                                  frequency_penalty: 0,
                                  presence_penalty: 0,
                                  stop: [],
                                  logit_bias: {},
                                  context_window: 0,
                                  createdBy: "",
                                }}
                              />
                            </Show>

                            <ThreadTimelineUnit tagLabel="Result">
                              <TextUnitV1
                                disableActions
                                message={{
                                  messageId: "",
                                  kind: ThreadMessageKinds.MessageKindTextV1,
                                  parts: [
                                    wire.services.limiting.guest.lastInteraction()?.type === "prompt"
                                      ? "Create an account to get more answers"
                                      : "Create an account to analyze your file.",
                                  ],
                                  provenance: {
                                    processedByModel: "Storytell",
                                    suggestedModels: [],
                                    transformationId: "",
                                    outputTokens: 0,
                                    inputTokens: 0,
                                    scoped: {
                                      worldKnowledge: false,
                                      collectionReferenceIDs: [],
                                      explicitAssets: [],
                                      collectionAssets: [],
                                      citations: [],
                                    },
                                  },
                                  isDone: true,
                                  label: "",
                                  textSuggestions: [],
                                  createdBy: "",
                                  tenantId: "",
                                  organizationId: "",
                                  threadId: "",
                                }}
                              />
                            </ThreadTimelineUnit>
                          </div>
                        </Show>
                      </Match>
                    </Switch>
                  }
                >
                  <div class="relative pl-6 md:pl-12 mb-16 w-[96%] mx-auto">
                    <div
                      aria-hidden
                      class="absolute left-0 top-14 rounded-full bottom-0 pointer-events-none w-[2px] bg-violet-700/40 dark:bg-violet-700"
                    />
                    <For each={group.content as ThreadMessage[]}>
                      {(message) => (
                        <Switch>
                          <Match when={isMessagePrompt(message)}>
                            <TextPromptUnitV1 message={message as curator.MessagePromptV2} />
                          </Match>
                          <Match when={isMessageTextV1(message)}>
                            <ThreadTimelineUnit tagLabel="Result">
                              <TextUnitV1 message={message as curator.MessageTextV1} />
                            </ThreadTimelineUnit>
                          </Match>
                          {/*<Match when={isContentSuggestedActions(message.content)}>*/}
                          {/*  <ThreadTimelineUnit*/}
                          {/*    tagLabel="Suggestions"*/}
                          {/*    menuItems={[*/}
                          {/*      {*/}
                          {/*        icon: IconName.ThumbsDown,*/}
                          {/*        label: "Don't create images automatically",*/}
                          {/*        onClick: () => {},*/}
                          {/*      },*/}
                          {/*    ]}*/}
                          {/*  >*/}
                          {/*    <SuggestedActionsUnitV1 message={message as Message} />*/}
                          {/*  </ThreadTimelineUnit>*/}
                          {/*</Match>*/}
                          {/*<Match*/}
                          {/*  when={isContentImageAssetPtrCollection(message.content)}*/}
                          {/*>*/}
                          {/*  <ThreadTimelineUnit tagLabel="Suggestions">*/}
                          {/*    <ImageAssetPtrCollection*/}
                          {/*      message={message as Message}*/}
                          {/*      thumbnail={true}*/}
                          {/*    />*/}
                          {/*  </ThreadTimelineUnit>*/}
                          {/*</Match>*/}
                        </Switch>
                      )}
                    </For>
                  </div>
                </Show>
              )}
            </For>
            <Show when={hasErrors()}>
              <div class="dark:bg-red-900 dark:text-white bg-red-300 text-black px-4 py-2">
                <For each={wire.services.websocket.snapshot.context.errorMessages}>
                  {(error) => (
                    <div class="my-2 text-base leading-normal">
                      Error: {error.message} ({error.code})
                    </div>
                  )}
                </For>
                <StButton label="Clear Errors" icon={TbCheck} onClick={handleClearErrors}>
                  Clear Errors
                </StButton>
                <StButton
                  label="Report Bug"
                  icon={TbBug}
                  onClick={() => {
                    wire.services.feedback.openFeedbackPanel();
                  }}
                >
                  Report Bug
                </StButton>
              </div>
            </Show>
          </div>
          <FloatingPromptBarContainer modifier="left">
            <PersistentPrompt />
          </FloatingPromptBarContainer>
          <div ref={threadEndRef} aria-hidden />
        </>
      </Match>
    </Switch>
  );
};

const useThreadAutoScroll = (
  ref: Accessor<HTMLElement | undefined>,
  threadEndRef: Accessor<HTMLElement | undefined>,
  // biome-ignore lint/suspicious/noExplicitAny: <explanation>
  messages: Accessor<any[]>,
  threadLoaded: Accessor<boolean>,
) => {
  const [params] = useSearchParams();

  const size = createElementSize(ref);

  let autoscroll = true;
  // Keep track of the length of the messages array to know if new prompts have been submitted
  let oldLength = 0;
  onMount(() => {
    const l = () => {
      const el = ref()?.parentElement;
      if (!el) return;

      if (Math.abs(el.scrollHeight - el.clientHeight - el.scrollTop) < 1) autoscroll = true;
      else autoscroll = false;
    };

    // Using wheel instead of "scroll" because scroll gets triggered when the window is scrolled
    // programmatically as well, we only want to interrupt the auto scroll when the user scrolls up
    // TODO: figure out setup for touch scrolling
    window.addEventListener("wheel", l, { passive: true });
    window.addEventListener("touchmove", l, { passive: true });
    onCleanup(() => {
      window.removeEventListener("wheel", l);
      window.removeEventListener("touchmove", l);
    });
  });

  const [scrollTo, setScrollTo] = createSignal("");
  createEffect(() => {
    if (typeof params.scrollTo === "string") {
      setScrollTo(params.scrollTo);
    }
  });

  let lastScrolledTo = "";

  createEffect(
    on(
      () => [threadLoaded(), size.height] as const,
      ([loaded]) => {
        if (!loaded) return;

        if (scrollTo() && lastScrolledTo !== scrollTo()) {
          const el = document.querySelector(`[data-block="${scrollTo()}"]`);
          if (el) {
            el.scrollIntoView({ behavior: "smooth", block: "start" });
            el.classList.add("scrollto-highlight");
            setTimeout(() => {
              el.classList.remove("scrollto-highlight");
            }, 999);
            lastScrolledTo = scrollTo() || "";
            return;
          }
        }
        if (lastScrolledTo !== "") return;

        // If new prompts have been submitted enable autoscroll again
        // even if the user interuppted it
        const len = messages().length;
        if (len !== oldLength) {
          oldLength = len;
          autoscroll = true;
        }

        if (autoscroll) threadEndRef()?.scrollIntoView({ behavior: "smooth", block: "end" });
      },
    ),
  );
};

const PredefinedPromptDetails: Component<{
  prompt?: CampaingPageDataPrompt;
}> = (props) => {
  const { editor } = usePromptContext();
  onMount(() => {
    editor()?.commands.focus();
  });

  return (
    <div class="px-20 py-20 flex flex-col justify-center items-center text-center gap-8 max-w-thread mx-auto dark:text-white">
      <h2 class="font-bold text-4xl leading-normal">{props.prompt?.prompt.name}</h2>

      <MarkdownRenderer highlight={false} md={props.prompt?.prompt.summary || ""} prefix="" />

      <Show when={props.prompt?.prompt.seoContent}>
        <section class="text-left">
          <MarkdownRenderer highlight={false} md={props.prompt?.prompt.seoContent || ""} prefix="" />
        </section>
      </Show>
    </div>
  );
};

const EmptyThreadMessage: Component = () => {
  const { editor, setShowUploadModal } = usePromptContext();
  onMount(() => {
    editor()?.commands.focus();
  });

  return (
    <div class="px-20 py-20 flex flex-col justify-center items-center text-center gap-4 max-w-thread mx-auto dark:text-white">
      <h2 class="font-bold text-4xl leading-normal">What do you want to accomplish?</h2>
      <p class="text-lg leading-normal">
        Start a new thread by submitting a prompt or by{" "}
        <button
          type="button"
          class="underline underline-offset-2 leading-normal text-lg w-auto"
          onClick={() => {
            setShowUploadModal(true);
          }}
        >
          uploading a file
        </button>
      </p>
    </div>
  );
};
