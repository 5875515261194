import type { ParentProps } from "solid-js";
import { classNames } from "~/lib/classNames";
import styles from "./ThreadContainer.module.css";

interface Props extends ParentProps {
  class?: string;
}

export const ThreadContainer = (props: Props) => (
  <section {...props} class={classNames(styles.threadContainer, props.class)}>
    {props.children}
  </section>
);
