import { stAnalytics } from "@repo/analytics";
import { RiSystemUploadCloud2Line } from "solid-icons/ri";
import { TbMessage2Plus } from "solid-icons/tb";
import { type Accessor, Show, createEffect, createSignal } from "solid-js";
import { usePromptContext } from "~/domains/chat/prompt/PromptContext";
import { useCreateNewSmartchatInCollection } from "~/domains/threads/hooks/useCreateNewSmartchatInCollection";
import type { ThreadGroup, ThreadUnit } from "~/domains/threads/screens/ThreadScreen";
import { getScreenType } from "~/lib/ui/getScreenType";
import { useWire } from "~/wire";
import { SecondaryCTA } from "../CTA/SecondaryCTA";
import { CollectionBreadcrumbs } from "./CollectionBreadcrumbs";
import styles from "./ThreadTop.module.css";

interface Props {
  messages: Accessor<(ThreadGroup | ThreadUnit)[]>;
  breadcrumbs: Breadcrumb[];
}

export const ThreadTop = (props: Props) => {
  const wire = useWire();
  const { activeCollection } = usePromptContext();
  const col = () => activeCollection();
  const { onCreateSmartchat } = useCreateNewSmartchatInCollection(() => activeCollection()?.id);
  const [collectionCount, setCollectionCount] = createSignal<number>(
    // biome-ignore lint/style/noNonNullAssertion: <explanation>
    col()?.id ? wire.services.collections.getCollectionDescendantsIds(col()!.id).length : 0,
  );

  const { setShowUploadModal } = usePromptContext();

  createEffect(() => {
    setCollectionCount(
      // biome-ignore lint/style/noNonNullAssertion: <explanation>
      col()?.id ? wire.services.collections.getCollectionDescendantsIds(col()!.id).length : 0,
    );
  });

  return (
    <>
      <div class={styles.threadTop}>
        <div class={styles.threadTopLeft}>
          <CollectionBreadcrumbs inline max={3} breadcrumbs={props.breadcrumbs || []} isChat />
        </div>
        <div class={styles.threadTopRight}>
          <Show when={props.messages().length > 0}>
            <SecondaryCTA
              label="Start new chat"
              data-test-id="thread-top-create-chat"
              icon={TbMessage2Plus}
              onClick={() => {
                onCreateSmartchat();
                stAnalytics.track("click_tracking", {
                  cta: "create_chat",
                  position: "chat_header",
                  screen: getScreenType(),
                });
              }}
              accessibleSuffix="in your current collection."
              inline
            />
          </Show>
          <SecondaryCTA
            label="Add asset"
            data-test-id="thread-top-upload"
            icon={RiSystemUploadCloud2Line}
            onClick={() => {
              setShowUploadModal(true);
              stAnalytics.track("click_tracking", {
                cta: "upload",
                position: "chat_header",
                screen: getScreenType(),
              });
            }}
            accessibleSuffix="to this chat's collection."
            inline
          />
          {/* <span class="screen-reader">This thread has</span>
          <Show when={collectionCount() > 0}>
            <p class={styles.threadTopStats}>
              <StIcon icon={TbKeyframes} class={styles.threadTopStatsIcon} size="1.125rem" aria-hidden={true} />
              <span class={styles.threadTopStatsText}>
                {`${collectionCount()} ${collectionCount() > 1 ? "collections" : "collection"}`}{" "}
              </span>
            </p>
          </Show>
          <span class="screen-reader">and</span>
          <p class={styles.threadTopStats}>
            <StIcon icon={TbArchive} class={styles.threadTopStatsIcon} size="1.125rem" aria-hidden={true} />
            <span class={styles.threadTopStatsText}>117 total assets</span>
          </p>
          <span class="screen-reader">in scope.</span> */}
        </div>
      </div>
    </>
  );
};
